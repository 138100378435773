<style>
  div {
    background-color: var(--sproud);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: stretch;
  }

  img {
    height: 2em;
    margin: 2em 2em 1em 2em;
  }

  @media (max-width: 640px) {
    div {
      display: block;
      background-color: var(--sproud);
    }

    img {
      height: 1em;
      margin: 1em 1em 0.5em 1em;
    }
  }
</style>

<div>
  <img src="iteam.png" alt="Iteam" />
</div>
